import { GoArrow } from '@/icons';

import * as Styled from './LightboxPlayer.styles';

interface LightboxNavigationProps {
  nextMomentHandler: () => void;
  prevMomentHandler: () => void;
  hasNextArrow: boolean;
  hasPreviousArrow: boolean;
}

export function LightboxNavigation({
  nextMomentHandler,
  prevMomentHandler,
  hasNextArrow,
  hasPreviousArrow,
}: LightboxNavigationProps) {
  return (
    <>
      <Styled.LightboxArrows>
        {hasPreviousArrow && (
          <Styled.LightboxLeftArrow onClick={prevMomentHandler} aria-label="Go to previous item">
            <GoArrow className="lightbox-left-arrow-icon" />
          </Styled.LightboxLeftArrow>
        )}
        {hasNextArrow && (
          <Styled.LightboxRightArrow onClick={nextMomentHandler} aria-label="Go to next item">
            <GoArrow className="lightbox-right-arrow-icon" />
          </Styled.LightboxRightArrow>
        )}
      </Styled.LightboxArrows>
    </>
  );
}
