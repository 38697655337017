import { LightboxPlayer } from '@/features/lightbox-player';
import { NormalizedVideoItem } from '@/features/normalize-data';
import { WidgetConfiguration } from '@/features/widget-preset';

interface Props {
  item: NormalizedVideoItem;
  onClose: () => void;
  configuration: WidgetConfiguration;
}

export function SingleItemPlayer({ item, onClose, configuration }: Props) {
  return (
    <LightboxPlayer
      items={[item]}
      index={0}
      openNext={() => undefined}
      openPrevious={() => undefined}
      onClose={onClose}
      configuration={configuration}
      hasNextPage={false}
      hasPreviousPage={false}
    />
  );
}
