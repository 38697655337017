import { VideoJSPlayerContextProvider } from '@entertainment-ai/react-component-library';
import React from 'react';

import { NormalizedVideoItem } from '@/features/normalize-data';
import { PlayerContainer } from '@/features/player-widget/PlayerContainer';
import { CloseIcon } from '@/icons';

import { WidgetConfiguration } from '../widget-preset';
import { LightboxNavigation } from './LightboxNavigation';

import * as Styled from './LightboxPlayer.styles';

interface LightboxPlayerProps {
  items: NormalizedVideoItem[];
  index: number;
  onClose: () => void;
  openNext: () => void;
  openPrevious: () => void;
  configuration: WidgetConfiguration;
  hasNextPage: boolean;
  hasPreviousPage: boolean;
}

export function LightboxPlayer({
  items,
  index,
  onClose,
  openNext,
  openPrevious,
  configuration,
  hasNextPage,
  hasPreviousPage,
}: LightboxPlayerProps) {
  const currentItem = items[index];
  const canShowArrows = items.length > 1;

  const handleClickOnBackground = (e: React.MouseEvent) => {
    if (e.currentTarget === e.target) {
      onClose();
    }
  };

  const firstFocusableElementRef = React.useRef<HTMLButtonElement>(null);
  const { focusTrap } = useFocusTrapEvents(firstFocusableElementRef, [index !== undefined]);

  return currentItem ? (
    <Styled.LightboxWrapper
      onKeyDown={(event: React.KeyboardEvent) => {
        if (event.key === 'Escape') {
          onClose();
        }
      }}
    >
      {focusTrap}
      <Styled.LightboxContainer onClick={(e: React.MouseEvent) => handleClickOnBackground(e)}>
        <Styled.LightboxClose ref={firstFocusableElementRef} onClick={onClose} aria-label="Close">
          <CloseIcon className="lightbox-close-icon" />
        </Styled.LightboxClose>
        {canShowArrows && (
          <LightboxNavigation
            nextMomentHandler={openNext}
            prevMomentHandler={openPrevious}
            hasNextArrow={hasNextPage}
            hasPreviousArrow={hasPreviousPage}
          />
        )}
        <Styled.LightboxContent>
          <Styled.ContainerPins>
            <Styled.ContainerRadius>
              {/* key is needed to reload the component with fresh state */}
              <VideoJSPlayerContextProvider key={currentItem.id}>
                <PlayerContainer videoItem={currentItem} configuration={configuration} autoplay />
              </VideoJSPlayerContextProvider>
            </Styled.ContainerRadius>
          </Styled.ContainerPins>
        </Styled.LightboxContent>
      </Styled.LightboxContainer>
      {focusTrap}
    </Styled.LightboxWrapper>
  ) : null;
}

function useFocusTrapEvents(
  firstFocusableElementRef: React.RefObject<HTMLElement>,
  focusDeps: any[],
) {
  const [isFocusInsideTrap, setIsFocusInsideTrap] = React.useState(false);

  React.useLayoutEffect(() => {
    if (!isFocusInsideTrap) {
      // eslint-disable-next-line no-unused-expressions
      firstFocusableElementRef.current?.focus();
      setIsFocusInsideTrap(true);
    }
  }, [firstFocusableElementRef, isFocusInsideTrap, ...focusDeps]);

  React.useEffect(() => {
    return () => {
      setIsFocusInsideTrap(false);
    };
  });

  const focusTrap = React.useMemo(
    () => (
      <div
        style={{ position: 'absolute', opacity: 0 }}
        // eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex
        tabIndex={0}
        onFocus={() => {
          // eslint-disable-next-line no-unused-expressions
          firstFocusableElementRef.current?.focus();
        }}
      />
    ),
    [firstFocusableElementRef],
  );

  return {
    focusTrap,
  };
}
