import { resetButton } from '@entertainment-ai/react-component-library';
import styled from 'styled-components';

// import { MOBILE_QUERY } from './styles/breakpoints';

export const LightboxWrapper = styled.div`
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999999;
  background-color: none;
  opacity: 0;
  background-color: rgba(0, 0, 0, 0.9);
  transition: opacity 1.3s ease;
  display: block;
  opacity: 1;
  animation: gfadeIn 0.5s ease;
`;

export const LightboxContainer = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
  z-index: 999999;
  display: flex;
`;

export const LightboxContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: left;
  width: 65vw;
  gap: 20px;
  max-height: 90vh;
  margin: auto;

  @media (max-width: 480px) {
    width: 80vw;
  }
`;

export const LightboxClose = styled.button`
  ${resetButton};
  top: 15px;
  right: 15px;
  position: absolute;
  color: rgba(255, 255, 255, 0.7);
  transition: color 0.3s ease;

  :hover {
    color: rgba(255, 255, 255, 0.9);
    transition: color 0.3s ease;
  }

  .lightbox-close-icon * {
    width: 20px;
    height: 20px;
  }
`;

export const LightboxArrows = styled.div`
  color: rgba(255, 255, 255, 0.7);
  top: 45%;
  cursor: pointer;

  .lightbox-left-arrow-icon *,
  .lightbox-right-arrow-icon * {
    width: 20px;
    height: 20px;
  }
`;

export const LightboxArrow = styled.button`
  ${resetButton};
  position: absolute;
  top: calc(50% - 25px);
  width: 20px;
  transition: color 0.3s ease;

  :hover {
    color: rgba(255, 255, 255, 0.9);
    transition: color 0.3s ease;
  }
`;

export const LightboxLeftArrow = styled(LightboxArrow)`
  left: 30px;
  .lightbox-left-arrow-icon {
    transform: rotate(-180deg);
  }

  @media (max-width: 480px) {
    left: 15px;
  }
`;
export const LightboxRightArrow = styled(LightboxArrow)`
  right: 30px;

  @media (max-width: 480px) {
    right: 0;
  }
`;

export const ContainerPins = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
`;

interface ContainerRadiusProps {
  isFullscreen?: boolean;
}

export const ContainerRadius = styled.div<ContainerRadiusProps>`
  display: block;
  border-radius: ${(props) => (props.isFullscreen ? 0 : '20px')};
  position: relative;
  overflow: hidden;

  /* to make overflow-hidden with border-radius work on Safari */
  -webkit-mask-image: -webkit-radial-gradient(white, black);

  width: 100%;
  height: 100%;

  ${'' /* ${MOBILE_QUERY} { */}
  @media (max-width: 320px) {
    border-radius: 10px;
  }
`;
